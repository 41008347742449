import { forwardRef } from "react";
import styles from "./styles/anchor.module.css";

export const A = forwardRef((props, ref) => {
  const { isExternal, underline, isButton, children, ...rest } = props;

  const underlineStyles = `${underline ? styles.underline : ""}`;
  const anchorStyles = `${isButton ? styles.button : ""}`;

  return (
    <a
      className={anchorStyles}
      target={isExternal ? "_blank" : undefined}
      rel={isExternal ? "noopener noreferrer" : undefined}
      ref={ref}
      {...rest}
    >
      <span className={underlineStyles}>{children}</span>
    </a>
  );
});
