import PropTypes from "prop-types";
import Head from "next/head";
import { SocialProfileJsonLd, LogoJsonLd } from "next-seo";
import { Header } from "@components/Header";

export function Shared({
  logoUrl,
  url,
  isPreview,
  title,
  navigation,
  announcement,
  logo,
  brandStory,
}) {
  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.svg" />
        <link rel="mask-icon" href="/mask-icon.svg" color="#000000" />
        <meta name="theme-color" content="#000" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <meta name="theme-color" content="#ffffff" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width, viewport-fit=cover" />
        <link
          rel="preload"
          href="/fonts/Sohne-Buch.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/Sohne-Kraftig.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/RecklessNeue-Thin.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/SohneBreit-Buch.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Head>
      {logoUrl && url && <LogoJsonLd url={url} logo={logoUrl} />}
      <SocialProfileJsonLd
        type="Organization"
        name="Kotn"
        url={process.env.BASE_URL}
        sameAs={[
          "http://www.facebook.com/kotnbasics",
          "http://instagram.com/kotn",
          "http://www.linkedin.com/in/kotn",
          "http://www.twitter.com/kotnbasics",
        ]}
      />

      <Header
        title={title}
        navigation={navigation}
        announcement={announcement}
        logo={logo}
        brandStory={brandStory}
        isPreview={isPreview}
      />
    </>
  );
}

Shared.propTypes = {
  logoUrl: PropTypes.string,
  url: PropTypes.string,
  isPreview: PropTypes.bool,
  title: PropTypes.string,
  navigation: PropTypes.arrayOf(PropTypes.object),
  announcement: PropTypes.object,
  logo: PropTypes.object,
  brandStory: PropTypes.string,
};
