import PropTypes from "prop-types";
import { useSelect } from "downshift";
import Icon from "./Icon";
import { flags } from "./flags";
import styles from "./styles/dropdown.module.css";

const DropDown = ({ items, selectedItem, handleSelectedItemChange }) => {
  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    id: "currency-switcher",
    items,
    selectedItem,
    onSelectedItemChange: handleSelectedItemChange,
  });
  return (
    <div className={styles.container}>
      <button className={styles.button} {...getToggleButtonProps()}>
        <Icon icon={selectedItem.toLowerCase()} viewBox="0 0 20 15" width="20" height="15">
          {flags[selectedItem.toLowerCase()]}
        </Icon>
        {selectedItem || "Select Currency"}
      </button>
      <ul className={styles.list} {...getMenuProps()}>
        {isOpen &&
          items.map((item, index) => {
            const listStyles = `${styles.listItem} ${
              highlightedIndex === index ? styles.highlighted : ""
            }`;
            return (
              <li className={listStyles} key={`${item}${index}`} {...getItemProps({ item, index })}>
                <Icon icon={item.toLowerCase()} viewBox="0 0 20 15" width="20" height="15">
                  {flags[item.toLowerCase()]}
                </Icon>
                {item}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

DropDown.propTypes = {
  items: PropTypes.array,
  selectedItem: PropTypes.string,
  handleSelectedItemChange: PropTypes.func,
};

export default DropDown;
