import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { SubscribeFormCheckboxes } from "./SubscribeFormCheckboxes";
import { SubscribeFormBody } from "./SubscribeFormBody";
import { SubscribeFormToggle } from "./SubscribeFormToggle";
import { SubscribeFormErrors } from "./SubscribeFormErrors";
import { useForm } from "../../hooks";
import { validate } from "./lib/validate";
import { trackEvent } from "@lib/track-event";
import { DISCLAIMER } from "./lib/constants";
import { logError } from "@lib/logger";
import styles from "./styles/form.module.css";

export function SubscribeForm({
  id,
  formSource,
  listID,
  buttonLabel,
  isSMS,
  showToggle,
  noOptions,
  setthankYouMessage,
  toggleForm,
  disclaimer,
  profileID,
}) {
  const formattedFormSource = `${formSource.trim().toLowerCase().replace(/\s/g, "-")}-${id}`;
  const womenKey = `women-${formattedFormSource}`;
  const menKey = `men-${formattedFormSource}`;
  const homeKey = `home-${formattedFormSource}`;
  const emailKey = `email-${formattedFormSource}`;
  const phoneKey = `sms-${formattedFormSource}`;
  let formType = "email";
  if (isSMS) {
    formType = "sms";
  }
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setIsSubmitting,
    isSubmitting,
    serverError,
    setServerError,
  } = useForm(
    {
      [womenKey]: false,
      [menKey]: false,
      [homeKey]: false,
      [emailKey]: "",
      [phoneKey]: "",
      formType,
      formSource: formattedFormSource,
    },
    subscribe,
    validate
  );

  async function subscribe() {
    const checkboxes = [];
    if (values[menKey]) {
      checkboxes.push("Male");
    }
    if (values[womenKey]) {
      checkboxes.push("Female");
    }
    if (values[homeKey]) {
      checkboxes.push("Home");
    }

    const subscription = {
      checkboxes,
      newsletter: listID,
      form: formSource,
    };
    if (values[emailKey]) {
      subscription.email = values[emailKey];
    }
    if (values[phoneKey]) {
      subscription.phone_number = "+1" + values[phoneKey].replace(/[\(\)\- ]/g, "");
      subscription.sms_consent = true;
    }
    if (profileID) {
      subscription.profileID = profileID;
    }

    try {
      const response = await fetch("/api/klaviyo-subscribe", {
        body: JSON.stringify(subscription),
        method: "POST",
        headers: { Accept: "application/json" },
      });

      const result = await response.json();
      setIsSubmitting(false);

      if (response.ok) {
        trackEvent("generate_lead", { email: subscription.email ?? result.body.email, checkboxes });
        trackEvent("submit_email_capture", { source: formSource });
        trackEvent("identify", { email: subscription.email ?? result.body.email });

        setthankYouMessage(true);
      } else {
        let err = "An Error occurred! Could not subscribe";
        if (result && result.body) {
          err = result.body;
        }
        setServerError(err);
      }
      if (formSource.trim().toLowerCase() === "modal") {
        Cookies.set("klaviyo_modal_subscribed", "true");
      }
    } catch (error) {
      logError(error);
      setServerError(error);
    }
  }

  const toggleFormInput = () => {
    if (isSMS) {
      values.formType = "email";
    } else {
      values.formType = "sms";
    }

    toggleForm();
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className={styles.section}>
        {!isSMS && !noOptions && (
          <SubscribeFormCheckboxes
            values={values}
            womenKey={womenKey}
            menKey={menKey}
            homeKey={homeKey}
            handleChange={handleChange}
          />
        )}

        <SubscribeFormBody
          values={values}
          emailKey={emailKey}
          phoneKey={phoneKey}
          formType={formType}
          handleChange={handleChange}
          errors={errors}
          isSubmitting={isSubmitting}
          buttonLabel={buttonLabel}
        />
        <SubscribeFormErrors
          errors={errors}
          serverError={serverError}
          isSubmitting={isSubmitting}
        />

        {showToggle && (
          <SubscribeFormToggle
            formType={formType}
            toggleForm={toggleFormInput}
            disclaimer={disclaimer}
          />
        )}
      </div>
    </form>
  );
}

SubscribeForm.propTypes = {
  id: PropTypes.string,
  formSource: PropTypes.string,
  listID: PropTypes.string,
  buttonLabel: PropTypes.string,
  isSMS: PropTypes.bool,
  noOptions: PropTypes.bool,
  disclaimer: PropTypes.string,
  setthankYouMessage: PropTypes.func.isRequired,
  toggleForm: PropTypes.func,
};

SubscribeForm.defaultProps = {
  id: "1",
  formSource: "Modal",
  listID: undefined,
  buttonLabel: "Join In",
  isSMS: false,
  noOptions: true,
  disclaimer: DISCLAIMER,
  toggleForm: () => {},
};
