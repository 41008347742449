const emailValidator =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneValidator = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

export function validate(values) {
  const errors = {};

  if (values.formType === "sms") {
    const smsKey = `sms-${values.formSource}`;
    if (!values[smsKey]) {
      errors.sms = "Phone number is required";
    }
    if (values[smsKey] && !phoneValidator.test(values[smsKey])) {
      errors.sms = "Must be a valid number";
    }
  } else if (values.formType === "email") {
    const emailKey = `email-${values.formSource}`;
    if (!values[emailKey]) {
      errors.email = "Email address is required";
    } else if (!emailValidator.test(values[emailKey])) {
      errors.email = "Email address is invalid";
    }
  }

  return errors;
}
