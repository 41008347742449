export function resolveMarket(location) {
  if (location) {
    const { city, country } = location;
    if (city === "Toronto") {
      return "Toronto";
    }
    if (city === "Montreal") {
      return "Montreal";
    }
    if (country === "CA") {
      return "Canada";
    }
    if (country === "US") {
      return "US";
    }
    if (country === null) {
      return "Unknown";
    }
  }

  return "International";
}
