import { cleanLink, titleCase } from "./formatString";
import { relativeLinkRegex } from "./constants";
import { getProductURLParams } from "./getProductURLParams";

function findProductPage(data, handle) {
  let shopifyProduct = null;
  const foundProductPage = data.find((productPage) => {
    shopifyProduct = productPage.shopifyProducts?.find((shopProduct) => {
      return handle === shopProduct.productReferenceV2.store.slug.current;
    });
    if (shopifyProduct) {
      return productPage;
    }
  });

  return { foundProductPage, shopifyProduct };
}

export const getLink = (cta) => {
  const { title, refTitle, handle, link, slug, type, productGroup } = cta;

  // Shopify Routes
  if (handle) {
    let url = "";
    let query = {};
    switch (type) {
      case "product":
        let foundProductPage = null;
        let shopifyProduct = null;
        if (productGroup && productGroup.data) {
          const { data } = productGroup;
          ({ foundProductPage, shopifyProduct } = findProductPage(data, handle));
        } else if (productGroup && productGroup.length) {
          ({ foundProductPage, shopifyProduct } = findProductPage(productGroup, handle));
        }
        const params = getProductURLParams(foundProductPage, shopifyProduct);

        if (foundProductPage) {
          url = `/products/${foundProductPage.slug.current}`;
        } else {
          url = "/";
        }

        if (params) {
          query = params;
        }

        break;
      default:
        url = "/";
        break;
    }
    return {
      href: url,
      query: query,
      text: title ? title : refTitle ? refTitle : titleCase(handle),
    };
  }

  // Next.js Routes
  if (slug) {
    let pathname = "/";
    switch (type) {
      case "hub":
        pathname = "/";
        break;
      case "editorial":
        pathname = "/editorials/";
        break;
      case "index":
        pathname = "/";
        break;
      case "about":
        pathname = "/about/";
        break;
      case "store":
        pathname = "/stores/";
        break;
      case "job":
        pathname = "/careers/";
        break;
      case "page":
        if (slug.current === "help") {
          pathname = "/";
        } else if (slug.current === "legal") {
          pathname = "/legal/";
        } else {
          pathname = "/pages/";
        }
        break;
      case "productPage":
        pathname = "/products/";
        break;
      case "collectionPage":
        pathname = "/collections/";
        break;
      case "bundle":
        pathname = "/products/";
        break;
      default:
        pathname = "/";
        break;
    }
    return {
      path: pathname,
      href: pathname + (slug.current === "legal" ? "terms-of-service" : slug.current),

      text: title ? title : refTitle ? refTitle : titleCase(slug.current),
    };
  }

  // Handle Absolute Links
  if (link) {
    // Relative Links
    try {
      const url = new URL(link.href);

      if (relativeLinkRegex.test(link.href)) {
        return {
          path: url.pathname,
          href: url.pathname,
          text: title ? title : link.href ? cleanLink(link.href) : null,
        };
      }
    } catch (error) {}

    // External Links
    return {
      href: link.href,
      blank: link.blank || false,
      text: title ? title : link.href ? cleanLink(link.href) : null,
    };
  }

  // Default
  return {
    href: "/",
    text: title ? title : refTitle ? refTitle : "home",
  };
};
