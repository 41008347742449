import PropTypes from "prop-types";
import styles from "./styles/form.module.css";

export function SubscribeFormInput({ values, emailKey, phoneKey, formType, handleChange }) {
  if (formType === "sms") {
    return (
      <label htmlFor={phoneKey}>
        <input
          className={styles.input}
          name={phoneKey}
          type="tel"
          autoComplete="tel"
          placeholder="416 555 2134"
          value={values[phoneKey] || ""}
          onChange={handleChange}
          required
        />
      </label>
    );
  }
  return (
    <label htmlFor={emailKey}>
      <input
        className={styles.input}
        name={emailKey}
        type="email"
        autoComplete="email"
        placeholder="you@email.com"
        value={values[emailKey] || ""}
        onChange={handleChange}
        required
      />
    </label>
  );
}

SubscribeFormInput.propTypes = {
  values: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])).isRequired,
  emailKey: PropTypes.string.isRequired,
  phoneKey: PropTypes.string.isRequired,
  formType: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};
