import PropTypes from "prop-types";
import styles from "./styles/form.module.css";

export const SubscribeFormErrors = ({ errors, serverError, isSubmitting }) => {
  let submitMessage = null;
  if (isSubmitting && Object.keys(errors).length === 0) {
    submitMessage = "Signing you up...";
  }
  if (!errors.checkboxes && !errors.email && !errors.sms && !submitMessage && !serverError) {
    return null;
  }
  return (
    <div className={styles.errorsContainer}>
      {errors.checkboxes && <p className={styles.errorsMessage}>{errors.checkboxes}</p>}
      {errors.email && <p className={styles.errorsMessage}>{errors.email}</p>}
      {errors.sms && <p className={styles.errorsMessage}>{errors.sms}</p>}
      {submitMessage && <p className={styles.errorsMessage}>{submitMessage}</p>}
      {serverError && <p className={styles.errorsMessage}>{serverError}</p>}
    </div>
  );
};

SubscribeFormErrors.propTypes = {
  errors: PropTypes.objectOf(PropTypes.string),
  serverError: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
};

SubscribeFormErrors.defaultProps = {
  errors: {},
  serverError: null,
};
