import styles from "./styles/navbar.module.css";

export function NavBarWrapper({ children, toggleDrawer }) {
  const wrapperStyles = `${styles.base} ${styles.wrapper}`;
  const navbarStyles = `${styles.base} ${styles.navbar}`;
  return (
    <div className={wrapperStyles} onMouseLeave={() => toggleDrawer(false)}>
      <nav className={navbarStyles}>{children}</nav>
    </div>
  );
}
