import PropTypes from "prop-types";
import { SubscribeFormInput } from "./SubscribeFormInput";
import styles from "./styles/form.module.css";

export function SubscribeFormBody({
  values,
  emailKey,
  phoneKey,
  formType,
  handleChange,
  errors,
  isSubmitting,
  buttonLabel,
}) {
  return (
    <div className={styles.container}>
      <SubscribeFormInput
        values={values}
        emailKey={emailKey}
        phoneKey={phoneKey}
        formType={formType}
        handleChange={handleChange}
      />
      <button
        className={styles.button}
        type="submit"
        disabled={Object.keys(errors).length === 0 && isSubmitting}
      >
        {buttonLabel}
      </button>
    </div>
  );
}

SubscribeFormBody.propTypes = {
  values: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])).isRequired,
  emailKey: PropTypes.string.isRequired,
  phoneKey: PropTypes.string.isRequired,
  formType: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};
