import { useState } from "react";
import PropTypes from "prop-types";

import Cta from "../../Cta";

function DrawerLink({ navLink }) {
  const [underline, setUnderline] = useState(false);

  return (
    <Cta
      navLink={navLink}
      underline={underline}
      onMouseEnter={() => setUnderline(true)}
      onMouseLeave={() => setUnderline(false)}
    />
  );
}

DrawerLink.propTypes = {
  navLink: PropTypes.shape({
    title: PropTypes.string,
    refTitle: PropTypes.string,
    handle: PropTypes.string,
    link: PropTypes.object,
    slug: PropTypes.object,
    type: PropTypes.string,
  }),
};

export default DrawerLink;
