import Error from "next/error";
import { NextSeo } from "next-seo";
import Footer from "./Footer";

export function NotFound({ footer }) {
  return (
    <>
      <NextSeo noindex={true} />
      <Error statusCode={404} title="This page could not be found" />
      <Footer navItems={footer} />
    </>
  );
}
