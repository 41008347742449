import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { CURRENCIES } from "./lib/constants";
import { LanguageToggle } from "./LanguageToggle";
import { DropDown } from "@components/DropDown";
import { useCurrencyDispatch, useCurrencyState } from "@hooks/index";
import styles from "./announcementBar.module.css";

export function AnnouncementBarLeft() {
  const dispatch = useCurrencyDispatch();
  const { currency } = useCurrencyState();
  const [isQuebec, setIsQuebec] = useState(false);

  const locationCookie = Cookies.get("location");

  useEffect(() => {
    const location = locationCookie && JSON.parse(locationCookie);
    const isQuebec = location?.country === "CA" && location?.region === "QC";
    if (location) setIsQuebec(isQuebec);
  }, [locationCookie]);

  const handleSelectedItemChange = ({ selectedItem }) => {
    dispatch({ type: "set", currency: selectedItem });
    Cookies.set("cart_currency", selectedItem);
  };

  return (
    <div className={styles.announcementLeft}>
      {currency && (
        <DropDown
          items={CURRENCIES}
          selectedItem={currency}
          handleSelectedItemChange={handleSelectedItemChange}
        />
      )}
      {/* TODO: @mudassirijaz786; for later use
      {/* {isQuebec && <LanguageToggle />} */}
      <LanguageToggle />
    </div>
  );
}
