import { getImages } from './getImages';

export const getOpenGraph = (openGraphImage, title) => {
  const images = openGraphImage
    ? getImages(openGraphImage, {
        large: {
          width: 800,
          height: 600,
        },
        medium: { width: 1200, height: 630 },
        small: { width: 600, height: 600 },
      })
    : {};
  return openGraphImage
    ? [
        {
          url: images.large,
          width: 800,
          height: 600,
          alt: title,
        },
        {
          // Facebook recommended size
          url: images.medium,
          width: 1200,
          height: 630,
          alt: title,
        },
        {
          // Square 1:1
          url: images.small,
          width: 600,
          height: 600,
          alt: title,
        },
      ]
    : [];
};
