import PropTypes from "prop-types";
import Link from "next/link";
import styles from "./styles/form.module.css";

export function SubscribeFormToggle({ formType, toggleForm, disclaimer }) {
  let buttonText = "Prefer SMS?";

  if (formType === "sms") {
    buttonText = "Prefer Email?";
  }
  const disclaimerStyle = `${styles.disclaimer} ${formType === "sms" ? styles.sms : ""}`;
  return (
    <>
      <button className={styles.toggle} type="button" onClick={toggleForm}>
        {buttonText}
      </button>
      <p className={disclaimerStyle}>
        {disclaimer} View our <Link href="/legal/privacy-policy">Privacy Policy</Link> and{" "}
        <Link href="/legal/terms-of-service">Terms of service.</Link>
      </p>
    </>
  );
}

SubscribeFormToggle.propTypes = {
  formType: PropTypes.string.isRequired,
  toggleForm: PropTypes.func.isRequired,
  disclaimer: PropTypes.string.isRequired,
};
