import PropTypes from "prop-types";

import Cta from "../../Cta";

function NavBarLink({ navLink, underline, handleShowDrawer }) {
  return <Cta navLink={navLink} onMouseEnter={() => handleShowDrawer(navLink)} />;
}

NavBarLink.propTypes = {
  navLink: PropTypes.shape({
    title: PropTypes.string,
    refTitle: PropTypes.string,
    handle: PropTypes.string,
    link: PropTypes.object,
    slug: PropTypes.object,
    type: PropTypes.string,
  }),
  underline: PropTypes.bool,
  handleShowDrawer: PropTypes.func,
};

export default NavBarLink;
