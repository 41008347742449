import styles from "./styles/iconbutton.module.css";

export function IconButton({ handleClick, children, size }) {
  if (handleClick) {
    const onClick = () => handleClick();
    return (
      <button
        className={styles.button}
        style={{ width: `${size}`, height: `${size}` }}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
  return (
    <button className={styles.button} style={{ width: `${size}`, height: `${size}` }}>
      {children}
    </button>
  );
}
