import { handleize } from "./formatString";

export const getProductURLParams = (productGroup, shopifyProduct) => {
  let navigation = {};
  if (productGroup && shopifyProduct) {
    if (shopifyProduct?.productColour) {
      navigation.colour = encodeURIComponent(
        handleize(shopifyProduct?.productColour?.title.toLowerCase())
      );
    }
  }

  return navigation;
};
